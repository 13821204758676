import React from "react"
import { graphql } from "gatsby"

import SEO from "@components/seo"
import Header from "@components/header"
import Footer from "@components/footer"
import Blocks from "@components/blocks"

const PageTemplate = ({ data: { page } }) => {
  const { seo, blocks } = page
  const { sections } = blocks

  return (
    <div>
      <Header />

      <main className={`inner-page-wrapper`}>
        <SEO seo={seo} />
        <Blocks blocks={sections} />
      </main>

      <Footer />
    </div>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query PageById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current post by id
    page: wpPage(id: { eq: $id }) {
      id
      title
      seo {
        title
        metaDesc
        metaKeywords
        canonical
        readingTime
        opengraphUrl
        opengraphType
        opengraphTitle
        opengraphSiteName
        opengraphPublisher
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphDescription
        opengraphAuthor
        opengraphImage {
          localFile {
            publicURL
          }
        }
        twitterDescription
        twitterTitle
        twitterImage {
          localFile {
            publicURL
          }
        }
      }
      blocks {
        sections {
          ... on WpPage_Blocks_Sections_LocationListing {
            fieldGroupName
            heading
            bodyText
            locations {
              title
              address
              phoneNumber {
                label
                phoneNumber
              }
            }
            image {
              altText
              localFile {
                publicURL
              }
            }
            links {
              anchorId
              customUrl
              externalUrl
              forceDownload
              linkText
              linkType
              pageLink {
                ... on WpPage {
                  id
                  uri
                }
                ... on WpProgram {
                  id
                  uri
                }
                ... on WpPost {
                  id
                  uri
                }
              }
              file {
                localFile {
                  publicURL
                }
              }
            }
            hideSection
            sectionSelectors {
              sectionClasses
              sectionId
            }
          }
          ... on WpPage_Blocks_Sections_ResourceListing {
            fieldGroupName
            heading
            bodyText
            resources {
              heading
              resources {
                title
                file {
                  localFile {
                    publicURL
                  }
                }
              }
            }
            hideSection
            sectionSelectors {
              sectionClasses
              sectionId
            }
          }
          ... on WpPage_Blocks_Sections_ProgramListing {
            fieldGroupName
            backgroundColor
            bodyText
            heading
            subheading
            programCategory {
              databaseId
              name
              programs {
                programs: nodes {
                  id
                  title
                  uri
                  menuOrder
                  programDetails {
                    ageGroup {
                      ageGroup
                    }
                    locations {
                      name
                    }
                    carouselImage {
                      altText
                      localFile {
                        publicURL
                      }
                    }
                  }
                }
              }
            }
            hideSection
            sectionSelectors {
              sectionClasses
              sectionId
            }
          }
          ... on WpPage_Blocks_Sections_TextColumns {
            fieldGroupName
            heading
            hideSection
            leftColumn
            rightColumn
            links {
              anchorId
              customUrl
              externalUrl
              forceDownload
              linkText
              linkType
              pageLink {
                ... on WpPage {
                  id
                  uri
                }
                ... on WpProgram {
                  id
                  uri
                }
                ... on WpPost {
                  id
                  uri
                }
              }
              file {
                localFile {
                  publicURL
                }
              }
            }
            sectionSelectors {
              sectionClasses
              sectionId
            }
          }
          ... on WpPage_Blocks_Sections_TextWithImage {
            alignment
            bodyText
            fieldGroupName
            heading
            style
            subheading
            image {
              altText
              localFile {
                publicURL
              }
            }
            links {
              anchorId
              customUrl
              externalUrl
              forceDownload
              linkText
              linkType
              pageLink {
                ... on WpPage {
                  id
                  uri
                }
                ... on WpProgram {
                  id
                  uri
                }
                ... on WpPost {
                  id
                  uri
                }
              }
              file {
                localFile {
                  publicURL
                }
              }
            }
            hideSection
            sectionSelectors {
              sectionClasses
              sectionId
            }
          }
        }
      }
    }
  }
`
